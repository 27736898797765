import ContactMe from "../ContactMe";
import Footer from "../Footer";

export default function Contact() {
    return(
        <>
        <ContactMe />
        <Footer />
        </>
    )
}